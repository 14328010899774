/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 *
 */

'use strict';

import React from 'react';
//var RB = require("react-bootstrap");
import { OverlayTrigger, Popover, Button, Alert, InputGroup, ButtonGroup, Tooltip, FormControl, Form, Row, Col } from 'react-bootstrap';

import moment from 'moment';
import Actions from './../../actions/Actions.js';
import VakiAPI from '../../webservice/VakiAPI.js';
import FilterStore from '../../stores/FilterStore';
import SessionStore from './../../stores/SessionStore.js';
import VConst from './../../utils/VConst.js';
import Curtain from './../../utils/Curtain.jsx';
import Immutable from 'immutable';
import TrendGraphStore from './../../stores/TrendGraphStore.js';
import OrgFarmPopPicker from '../Filters/OrgFarmPopPicker.jsx';
import MinWeightFilter from './../Filters/MinWeightFilter.jsx';
import MaxWeightFilter from './../Filters/MaxWeightFilter.jsx';
import MinHourCountFilter from './../Filters/MinHourCountFilter.jsx';
import SavedFiltersIndicator from './../Filters/SavedFiltersIndicator.jsx';
import TrendgraphArea2 from './TrendgraphArea2.jsx';
import DownloadCSV from './DownloadCSV.js';

import TrendgraphAvgWeightArea from './TrendgraphAvgWeightArea.jsx';
import Loader from './Loader.jsx';
import Spinner from './../../utils/Spinner.jsx';
import StartEndDatePicker from './../Filters/StartEndDatePicker.jsx';
import TranslateStore from './../../stores/TranslateStore.js';
import UserDataStore from './../../stores/UserDataStore.js';
import BlinkPop from './../../utils/BlinkPop/BlinkPop.jsx';





var graphHeight = "550px";

var t = TranslateStore.getTranslate();

import BGTools from './../../utils/BGtools.js';

require('./Trendgraph.css');

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

var thingToRender = [];

var doNoMore = false;

var trtrtr = 1;

/**
 * Component that takes care of displaying the Trend Graph and it's filters.
 */
export default class Trendgraph extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			filter: FilterStore.getFilter(),
			trendFilter: TrendGraphStore.getFilter(),
			trendGraphData: TrendGraphStore.getTrendGraphData(),
			trendGraphAllCagesData: TrendGraphStore.getAllCagesData(),
			renderFlags: 0,
			loadingNow: false,
			auxData: null,
			someError: false

	
		};

		/*******************componentWillMount ( )*********************** */
 

		if (SessionStore.isLoggedOut()) {
			        
			Actions.redirectToLogin();
		}

		var rFlags = VConst.TG_JUST_MOUNTED;

		if (TrendGraphStore.theNewLoading()) {
			rFlags |= VConst.TG_CLEAR | VConst.TG_LOAD_ON;
		} else {
			rFlags |= VConst.TG_REDRAW;
		}
		this.state.renderFlags = rFlags;
		/*
        this.setState({
            filter: FilterStore.getFilter(),
            trendFilter: TrendGraphStore.getFilter(),
            trendGraphData: TrendGraphStore.getTrendGraphData(),
            trendGraphAllCagesData: TrendGraphStore.getAllCagesData(),
            renderFlags: rFlags,
            loadingNow: (TrendGraphStore.getFetchStatus().length > 0),
            auxData: null
        });
*/
		/**************************************** */

		this.onTrendGraphStoreChange = (newTrendGraphStoreData, loadingMsg, renderFlags, aux) => {



		
			if (loadingMsg) return;




			var newF = 0;
			if (!renderFlags) newF = 0;
			else newF = renderFlags;

			if (newTrendGraphStoreData )  {

				this.setState({
					trendFilter: newTrendGraphStoreData.get('filter'),
					trendGraphData: newTrendGraphStoreData.get('trendGraphData'),
					trendGraphAllCagesData: newTrendGraphStoreData.get('allCagesData'),
					trendGraphAvgWeightData: newTrendGraphStoreData.get('avgWeightData'),
					renderFlags: newF,
					auxData: aux,
					someError: false
				});
			} else {
					
				if ((typeof aux) == "string" && aux.length > 5 && aux.substring(0,5) == "Error" ){
					this.setState({
						loadingMsg: false,
						auxData: aux,
						someError: true
					});
					return;
				}

				this.setState({});
				//this.state ={};
			}
		};

		this.onTranslateStoreChange = () => {
			
			this.setState({});
		};

		/*
     * Renders the view when Show average weight for all cages is clicked
     */
		this.getAvgWeightTrendGraph = () => {
			/* just use allCagesData
        if (!this.state.trendGraphAvgWeightData)
            return <div> </div>;

        var trendGraphs = this.state.trendGraphAvgWeightData.get("TrendGraphDataList");
        var startDate = this.state.trendGraphAvgWeightData.get("StartDate");
        var endDate = this.state.trendGraphAvgWeightData.get("EndDate");
        var maxAvgWeight = this.state.trendGraphAvgWeightData.get("MaxAverageWeight");
        var maxNumberOfFish = this.state.trendGraphAvgWeightData.get("MaxNumberOfFish");
        var loading = this.state.loading;
*/

			

			if (!this.state.trendGraphAllCagesData) return <div key={0}> </div>;

			var trendGraphs = this.state.trendGraphAllCagesData.get('TrendGraphDataList');
			if (!trendGraphs) return <div key={0}> </div>;

			var startDate = this.state.trendGraphAllCagesData.get('StartDate');
			var endDate = this.state.trendGraphAllCagesData.get('EndDate');
			var maxAvgWeight = this.state.trendGraphAllCagesData.get('MaxAverageWeight');
			var maxNumberOfFish = this.state.trendGraphAllCagesData.get('MaxNumberOfFish');
			var loading = this.state.loading;

			var doDrawTrends = this.state.trendFilter.get('showAWasTrendlines');
			//TrendGraphStore.showAWasTrendlines();

			for (var i = 0; i < trendGraphs.length; ++i) {
				if (trendGraphs[i].PopulationTrendDataList !== null) {
					return (
						<div className="text-center" key={i}>
							<TrendgraphAvgWeightArea
								trendGraphData={trendGraphs}
								startDate={startDate}
								endDate={endDate}
								loading={loading}
								maxAvgWeight={maxAvgWeight}
								maxNumberOfFish={maxNumberOfFish}
								t={t}
								drawTrends={doDrawTrends}
							/>

					<Form.Group >
						<Form.Check
							onChange={this.onShowTrendChange}
									checked={TrendGraphStore.showAWasTrendlines()}
                            label={t('trendgraph.showtrendlines')}
                            
						/>

					</Form.Group >


						</div>
					);
				}
			}
			Actions.trendGraphDoneLoading();
			return <Alert key={0} variant="warning">{t('trendgraph.farmsHaveNo')}</Alert>;
		};

		/**
     * Renders the view when Show all cages is clicked.
     */

		this.getAllTrendgraphs = () => {




			var trendGraphs = this.state.trendGraphAllCagesData.get('TrendGraphDataList');
			var startDate = this.state.trendGraphAllCagesData.get('StartDate');
			var endDate = this.state.trendGraphAllCagesData.get('EndDate');
			var maxAvgWeight = this.state.trendGraphAllCagesData.get('MaxAverageWeight');
			var maxNumberOfFish = this.state.trendGraphAllCagesData.get('MaxNumberOfFish');
			var loading = this.state.loading;

			if (!trendGraphs || trendGraphs.length == 0) {
				Actions.trendGraphDoneLoading();
				return <Alert key={0} variant="warning">{t('trendgraph.allPopsHasNo')}</Alert>;
			}
			var uniScaling = TrendGraphStore.uniformScaling();
			var alignedDates = TrendGraphStore.useSpecEndDates();

			var _aux = this.state.auxData;
			var allTrends = trendGraphs.map(function(data, i) {
				if (alignedDates) {
					startDate = data.StartDate;
					endDate = data.EndDate;
				}
				if (!data.PopulationTrendDataList) {
					var populationName = FilterStore.getPopulationNameByID(data.PopulationID);
					Actions.trendGraphDoneLoading();
					var theReturn = <div key={64534+i}/>;

					//if (!UserDataStore.showInactive()) {
						theReturn = (
							<Alert variant="warning" key={i}>
								<strong>{populationName}</strong> {t('trendgraph.allPopsHasNo')}
							</Alert>
						);
					//}
					return theReturn;
				}
				if (uniScaling) {
				
					return (
						<div>
						<DownloadCSV  TheData = {data}/>
						<TrendgraphArea2
							height={graphHeight}
							trendGraphData={data}
							key={i}
							allCages={true}
							startDate={startDate}
							endDate={endDate}
							loading={loading}
							maxAvgWeight={maxAvgWeight}
							maxNumberOfFish={maxNumberOfFish}
							aux={_aux}
							t={t}
						/>

						
	
						</div>
					);
				} else {
					return (
						<div>
								<DownloadCSV  TheData = {data}/>
						<TrendgraphArea2
							height={graphHeight}
							trendGraphData={data}
							key={i}
							allCages={true}
							startDate={startDate}
							endDate={endDate}
							loading={loading}
							aux={_aux}
							t={t}
						/>
						</div>
					);
				}
			});
			console.log ("getAll  5")
			return <div>{allTrends}</div>;
		};
	} //Constructor

	componentDidMount() {
	
		UserDataStore.postPageHit(VConst.PAGEID.TRENDGRAPH);

		this.unsubscribeTrendGraphStore = TrendGraphStore.listen(this.onTrendGraphStoreChange);
		this.unsubscribeTranslateStore = TranslateStore.listen(this.onTranslateStoreChange);
	}

	componentWillUnmount() {
		this.unsubscribeTrendGraphStore();
		this.unsubscribeTranslateStore();
	}

	onShowAllCagesCheck() {
		Actions.trendShowAllCages();
	}

	onShowAverageWeight() {
		Actions.trendShowAvgWeight();
	}

	onUniformScaling() {
		Actions.trendUniformScaling();
	}

	onShowTrendChange() {
		Actions.trendAWshowAsTrend();
	}

	onUseSpecEndDatesChange() {
		Actions.trendUseSpecEndDates();
	}

	handleFileInput(evt) {
		if (window.File && window.FileReader && window.FileList && window.Blob) {
			// Great success! All the File APIs are supported.
		} else {
			alert('The File APIs are not fully supported in this browser.');
		}   
		var output = [];
		var files = evt.target.files; // FileList object
		var reader = new FileReader();

		var fname = '';
		if (files && files.length > 0) fname = files[0].name;

		reader.onload = function(e) {
		
			// Print the contents of the file
			var text = e.target.result;

			var lines = text.split(/[\r\n]+/g); // tolerate both Windows and Unix linebreaks

			var dataFound = false;
			var cntNoData = 0;

			var seps = [ '\t', ';', ',' ];

			var sep;

			for (var s = 0; s < 3 && !dataFound; s++) {
				output = [];
				sep = seps[s];
				cntNoData = 0;
				for (var i = 0; i < lines.length; i++) {
					if (cntNoData == 10 && !dataFound) {
						if (s < 2) break;
						var str = '';
						for (var j = 0; j < cntNoData; j++) {
							str += lines[j] + '\n';
						}
						alert(
							'No valid data found in fyrst 10 lines of file (Expecting datalines as: [DATE;VALUE]: \n' +
								'Date should be be on ISO format: YYYY-MM-DD and Value should be the weight in grams as integer \n' +
								str
						);
						return;
					}

					var aLin = lines[i].split(sep);

					if (aLin.length != 2) {
						cntNoData++;
						continue;
					}

					var dt = new Date(Date.parse(aLin[0]));

					var v = parseInt(aLin[1]);

					if (isNaN(dt) || isNaN(v)) {
						console.warn('Invalid in aux data:  ' + lines[i]);
						cntNoData++;
						continue;
					}

					output.push({
						key: i,
						Date: moment.utc(dt),
						Value: v
					});

					dataFound = output.length > 0;
				}
			}

			Actions.trendNewAux(output, fname);
		};

		reader.readAsText(files[0], 'UTF-8');
	}

	testClick() {
		if (TrendGraphStore.showAllCages()) {
			alert('To activate this feature you need to uncheck the [Show all cages] option!');
			return;
		}
		document.getElementById('formControlsFile').click();
	}

	render() {


	let theTip = "Þetta er tooltip"
/*
<Tooltip placement="bottom" trigger={['hover']} multiline={true} mouseEnterDelay={0.6} overlayStyle={{opacity: 0.9, padding: 10}}
overlay={<div style={{padding: 4}}>{theTip}</div>}
>

</Tooltip>

*/




		if (doNoMore) return;

		var RoundNotify = (
			<div className="font65">Used weight filters may be rounded to predefined discrete values</div>
		);
		var grpOrgDate = '';
		//The filter groups
		grpOrgDate = (
			<div className="clsItemWrap">
				<div className="clsOrgWrap">
                <Row>
					<div>
						<OrgFarmPopPicker filter={this.state.filter} />
					</div>
                    </Row>
                    <Row>
					<div className="clsDateWrap">
						<div className="clsFlexDate">
							<StartEndDatePicker
								startDate={this.state.trendFilter.get('startDate')}
								//startDateActionFn={Actions.trendStartDateUpdate}
								endDate={this.state.trendFilter.get('endDate')}
								//endDateActionFn={Actions.trendEndDateUpdate}
								dateChangeActionFn={Actions.trendDateChange}
								format={'MMM.DD'}
							/>

							<SavedFiltersIndicator />
						</div>
					</div>
                    </Row>
				</div>
			</div>
		);





		var grpCheckBoxes = (
			<div className="clsItemWrap">
				<Form vertical = {"true"}>
					<Form.Group className="mb-0" >
						<Form.Check
							
							onChange={this.onShowAllCagesCheck}
							checked={TrendGraphStore.showAllCages()}
							disabled={TrendGraphStore.showAvgWeight()}
                            label={t('trendgraph.showAllCages')}
                            
						/>

					</Form.Group >
					<Form.Group className="mb-0 " >
						<Form.Check
							
							onChange={this.onShowAverageWeight}
							checked={TrendGraphStore.showAvgWeight()}
                            label={t('trendgraph.showAvgWeight')}
						/>
						
					</Form.Group >
					<Form.Group className="mb-0" >
						<Form.Check
							
							onChange={this.onUniformScaling}
							checked={TrendGraphStore.uniformScaling()}
							disabled={TrendGraphStore.showAvgWeight()}
                            label={t('trendgraph.uniformScaling')}
						/>
						

					</Form.Group >
					<Form.Group className="mb-0" >
						<Form.Check
							
							onChange={this.onUseSpecEndDatesChange}
							checked={TrendGraphStore.useSpecEndDates()}
							disabled={TrendGraphStore.showAvgWeight()}
                            label={'Aligned date ranges'}
						/>
					</Form.Group >
				</Form>
			</div>
		);

		/*

               <BlinkPop ID="TGMinWgt" User={UserDataStore.getUserName()} Text={t("trendgraph.minempty")}>
                    <MinWeightFilter
                        initValue={this.state.trendFilter.get("minWeight")}
                        maxValue={this.state.trendFilter.get("maxWeight")}
                        allowempty
                        actionFunction={Actions.minWeightUpdate} t={t} />
                </BlinkPop>

                <MaxWeightFilter
                    initValue={this.state.trendFilter.get("maxWeight")}
                    minValue={this.state.trendFilter.get("minWeight")}
                    allowempty
                    actionFunction={Actions.maxWeightUpdate} t={t} />
                {RoundNotify}
 */

		/*
           <ButtonGroup vertical>
           
                    <MinWeightFilter
                        initValue={this.state.trendFilter.get("minWeight")}
                        maxValue={this.state.trendFilter.get("maxWeight")}
                        allowempty
                        actionFunction={Actions.minWeightUpdate} t={t} />
            </ButtonGroup>
*/

		var grpWeightFilter = (
			<div className="clsItemWrap">
				<MinWeightFilter
					initValue={this.state.trendFilter.get('minWeight')}
					maxValue={this.state.trendFilter.get('maxWeight')}
					allowempty
					actionFunction={Actions.minWeightUpdate}
					t={t}
				/>
				<MaxWeightFilter
					initValue={this.state.trendFilter.get('maxWeight')}
					minValue={this.state.trendFilter.get('minWeight')}
					allowempty
					actionFunction={Actions.maxWeightUpdate}
					t={t}
				/>



  



				<MinHourCountFilter
					initValue={this.state.trendFilter.get('MinFishPrHour')}
					minValue={0}
					allowempty
					actionFunction={Actions.minHourCountUpdate}
					t={t}
				/>




				{RoundNotify}
			</div>
		);

		

		var grpButtons = (
			<div className="clsItemWrap">
				<div className="hidden-print">
					<ButtonGroup vertical={"true"} >
						<OverlayTrigger
							placement="bottom"
							delayShow={500}
							delayHide={150}
							overlay={
								<Tooltip>
									<div className="text-left">
										An option to upload a weight curve from a local file and plot on the current
										trendgraph for comparison. <br />
										The file must be be a text file with semicolon (;), comma(,) or TAB separated
										values in each line on the form: <br />
										DATE;Vale<br />
										The date must be in ISO format (like "2014-1-17") and the weight value as grams
										(integer)
									</div>
								</Tooltip>
							}
						>
							<Button type="button" onClick={this.testClick} inline="true" variant="outline-dark">
								Import Aux. Weights
							</Button>
						</OverlayTrigger>
						<div id="auxFileName" />
						<input
							id="formControlsFile"
							type="file"
							label="Show Aux. Weights"
							disabled={TrendGraphStore.showAllCages()}
							onChange={this.handleFileInput}
							className="hidden"
						/>
						<Button
							type="button"
                            variant="outline-dark"
							disabled={autoDisabled}
							onClick={function() {
								FilterStore.onMinWeightUpdate(null);
								FilterStore.onMaxWeightUpdate(null);
								//Actions.trendUseAutoFilters()
							}}
							inline="true"
						>
							Use auto filters
						</Button>
						<Button
							type="button"
                            variant="outline-dark"
							onClick={function() {
								FilterStore.onMinWeightUpdate(100);
								FilterStore.onMaxWeightUpdate(13000);
								//Actions.trendUseAutoFilters()
							}}
							inline="true"
						>
							Use default filters
						</Button>
					</ButtonGroup>
				</div>
			</div>
		);

		var r = window.devicePixelRatio;

	

		var grpFilters = (
			<div className="clsTrendControlsWrap">
			<Row>
			<Col>
				{grpOrgDate}
			</Col>
			<Col>							
				{grpButtons}
			</Col>
			<Col>							
				{grpCheckBoxes}
			</Col>
			<Col>							
				{grpWeightFilter}
			</Col>				
				</Row>
			</div>
		);

		if (this.state.someError){

			return (<div>
				{grpFilters}

				<Alert variant="danger"> <Alert.Heading>Error </Alert.Heading> {this.state.auxData} </Alert>
			</div>);

		}

		//Check specialcase - it is if data is loading whie entering trendgraph page
		if ((this.state.renderFlags & VConst.TG_JUST_MOUNTED) > 0 && TrendGraphStore.getFetchStatus() != '') {
			if (
				TrendGraphStore.showAvgWeight() //Fix - turn it off in this case
			)
				TrendGraphStore.resetShowAvgWeight();


			thingToRender = [];

			if (
				!TrendGraphStore.showAvgWeight() &&
				this.state.trendGraphAllCagesData &&
				this.state.trendGraphAllCagesData.get('TrendGraphDataList')
			) {
				var len = this.state.trendGraphAllCagesData.get('TrendGraphDataList').length;

				for (var i = 0; i < len; i++) {
					var dStart = null;
					var dEnd = null;
					if (TrendGraphStore.useSpecEndDates()) {
				
		

						dStart = moment.utc(this.state.trendGraphAllCagesData.TrendGraphDataList[i].StartDate);
						dEnd = moment.utc(this.state.trendGraphAllCagesData.TrendGraphDataList[i].EndDate);
					}

					thingToRender.push('');

					var theTrendData = this.state.trendGraphAllCagesData.get('TrendGraphDataList')[i];

					thingToRender[i] = (
						<div>
						<DownloadCSV  TheData = {theTrendData}/>
						<TrendgraphArea2
							key={i}
							height={graphHeight}
							trendGraphData={theTrendData}
							allCages={false}
							loading={false}
							startDate={dStart}
							endDate={dEnd}
							aux={this.state.auxData}
							t={t}
						/>
						</div>
					);
				}
			}
			this.state.renderFlags = 0;
		}

		if ((this.state.renderFlags & VConst.TG_CLEAR) > 0) {
			
			thingToRender = <div key={0}> </div>;
		} else if ((this.state.renderFlags & VConst.TG_REDRAW) > 0) {
			
			var len = thingToRender.length;
			for (var i = 0; i < len; i++) delete thingToRender[i];
			thingToRender = [];
			thingToRender.push(<div key={0}></div>);

			if (TrendGraphStore.showAllCages() && !TrendGraphStore.showAvgWeight()) {
			
				//thingToRender[0] = this.getAllTrendgraphs();
				thingToRender = this.getAllTrendgraphs();
			} else {
				if (TrendGraphStore.showAvgWeight()) {
					thingToRender[0] = this.getAvgWeightTrendGraph();
				} else {
				
					var trendData = null;
					var _aux = null;
					var popIdx = TrendGraphStore.getIndexOfPop(FilterStore.getSelectedPopulationID());
					if (popIdx >= 0) {
						var tl = this.state.trendGraphAllCagesData.get('TrendGraphDataList');
						if (tl) {
							//var tg = tl[popIdx];
							//trendData = tg.get("PopulationTrendDataList");

							var pID = FilterStore.getSelectedPopulationID();
							trendData = TrendGraphStore.getTrendForPop(pID);

							if (this.state.auxData) {
								if (pID == this.state.auxData.PopulationID) _aux = this.state.auxData;
							}

							if (trendData) {
								var v = trendData.PopulationTrendDataList;
								if (!v || v.length == 0) trendData = null;
							}
						}
					}

					if (trendData) {

			
						thingToRender[0] = (
							<div>
							<DownloadCSV  TheData = {trendData}/>
							<TrendgraphArea2
								key={0}
								height={graphHeight}
								trendGraphData={trendData}
								allCages={false}
								loading={false}
								aux={this.state.auxData}
								t={t}
							/>
							</div>
						);
					} else {

						thingToRender[0] = (
							<Alert variant="warning" key={0}>
								{FilterStore.getSelectedPopulationName()}: {t('trendgraph.popHasNo')}{' '}
							</Alert>
						);
					}
				}
			}
		} else if ((this.state.renderFlags & VConst.TG_NEWDATA) > 0) {

			if ((this.state.renderFlags & VConst.TG_RESET) > 0) {
				var len = thingToRender.length;
				for (var i = 0; i < len; i++) delete thingToRender[i];

				thingToRender = [];
			}

			if (!(thingToRender.constructor === Array)) thingToRender = [];

			thingToRender.push(<div key={0} />);

			var idx = thingToRender.length - 1;

		
			/*
            if (idx==0) {
                this.state.renderFlags = 0;
                return;
            }
            */
			var trendDataList = null;
			{
				if (this.state.trendGraphAllCagesData) {
					var tl = this.state.trendGraphAllCagesData.get('TrendGraphDataList');
					
					if (tl) {

						var tg = tl[idx];

						if (!tg) {

							return <Alert key={0} variant="danger"> <Alert.Heading>Error</Alert.Heading>  Ambiguous data received. You may have to refresh the page </Alert>;
							
						}
						// trendDataList = tg.get("PopulationTrendDataList");
						trendDataList = tg.PopulationTrendDataList;
					}
				}
			}

			if (TrendGraphStore.showAllCages()) {

				if (trendDataList === undefined || trendDataList === null || trendDataList.length <= 1) {
	
					thingToRender[idx] = (

						<Alert variant="warning"  key={idx}>
							{FilterStore.getPopNameByIdx(idx)}: {t('trendgraph.popHasNo')}{' '}
						</Alert>
					);
					Actions.trendGraphDoneLoading();
				} else {
					if (TrendGraphStore.showAvgWeight()) {
						thingToRender[idx] = this.getAvgWeightTrendGraph();
					} else {

				
						var dStart = null;
						var dEnd = null;
						if (TrendGraphStore.useSpecEndDates()) {
							dStart = moment.utc(this.state.trendGraphData.StartDate);
							dEnd = moment.utc(this.state.trendGraphData.EndDate);
	
					}




						thingToRender[idx] = (
							<div className = "trendItem" key={idx}>
							<TrendgraphArea2
								key={idx}
								height={graphHeight}
								trendGraphData={this.state.trendGraphData}
								allCages={false}
								loading={false}
								startDate={dStart}
								endDate={dEnd}
								aux={this.state.auxData}
								t={t}
							/>
							<br/>
							</div>
						);
						
					}
				}
				// thingToRender[idx] = this.getAllTrendgraphs();
			} else if (TrendGraphStore.showAvgWeight()) {

				thingToRender[idx] = this.getAvgWeightTrendGraph();
			} else if (trendDataList === undefined || trendDataList === null || trendDataList.length <= 1) {

				thingToRender[idx] = (
					<Alert key={idx} variant="warning">
					
						{FilterStore.getPopNameByIdx(idx)}: {t('trendgraph.popHasNo')}
					</Alert>
				);
				Actions.trendGraphDoneLoading();
			} else {
				if (trendDataList === undefined || trendDataList === null || trendDataList.length <= 1) {

					thingToRender[idx] = (
						<Alert variant="warning"  key={idx}>
							{FilterStore.getPopNameByIdx(idx)}: {t('trendgraph.popHasNo')}{' '}
						</Alert>
					);
					Actions.trendGraphDoneLoading();
				} else {
			
					thingToRender[idx] = (
						<TrendgraphArea2
							key={idx}
							height={graphHeight}
							trendGraphData={this.state.trendGraphData}
							allCages={false}
							loading={false}
							aux={this.state.auxData}
							t={t}
						/>
					);
				}
			}
		}

		if ((this.state.renderFlags & VConst.TG_LOAD_ON) > 0) {
			this.state.loadingNow = true;
		}
		if ((this.state.renderFlags & VConst.TG_LOAD_OFF) > 0) {
			this.state.loadingNow = false;
		}
		var theSpinnerThing = '';

		if (this.state.loadingNow) theSpinnerThing = <Spinner loadStatus={TrendGraphStore.getFetchStatus} />;

		

		this.state.renderFlags = 0;

		var autoDisabled = false;

		if (this.state.trendFilter.get('minWeight') == null && this.state.trendFilter.get('maxWeight') == null)
			autoDisabled = true;






		return (
			<div>
				{grpFilters}

				<div>{theSpinnerThing}</div>

				{thingToRender}
			</div>
		);
	}
}
