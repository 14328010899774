import React from "react";
import "./DownloadCSV.css";
import { Row, Col, Button } from "react-bootstrap";
var FileSaver = require("file-saver");
var FilterStore = require("../../stores/FilterStore");
var TranslateStore = require("./../../stores/TranslateStore.js");
var t = TranslateStore.getTranslate();
var moment = require("moment");
/*
    A container that is used to download the relevant files. Renderd inside of InformationTab
*/




export default class DownloadCSV extends React.PureComponent {
  constructor() {
    super();

  }

 
  handleClick = () => {


    var list = this.props.TheData.PopulationTrendDataList;

    var popID = this.props.TheData.PopulationID
  
    var populationName = FilterStore.getPopulationNameByID(popID) || "";

    var strTitle =  t("trendgraph.graphHeader") + populationName;

    try {





      var str = "";
      var csvTab = [];
      var strSep = "\t";

      var filt = FilterStore.getFilter();

      strSep = ",";
      csvTab.push("sep=" + strSep);

      

      str = t("filters.date")+strSep+filt.startDate+strSep+t("filters.to")+strSep+filt.endDate;
      csvTab.push(str);



      csvTab.push(t("filters.minWeight")+strSep+filt.minWeight);
      csvTab.push(t("filters.maxWeight")+strSep+filt.maxWeight);


      csvTab.push("");
  

      csvTab.push("Date, AvgWeight, NumFish, Temperature, ConditionFactor, TrendLine")


      csvTab.push("");
      var numCols = 4;;


      var strIdx = -1;

     var dataLen = list.length;
   

      for (var j=0; j<dataLen; j++) {
          str = "";

          str = moment(list[j].Date).format ("YYYY-MM-DD") + strSep + 
          (!list[j].AverageWeight?"":list[j].AverageWeight)
          +strSep+
          list[j].NumberOfFish
          +strSep
          +(!list[j].AverageFrameTemperature?"": list[j].AverageFrameTemperature)
                +strSep+
           (!list[j].ConditionFactor?"": list[j].ConditionFactor)+strSep+list[j].TrendLine


          csvTab.push(str);

       //   startDate = new Date (startDate.setDate(startDate.getDate() + 1));
      }
      str = csvTab.join("\r\n");

      /*
       for (var prop in this.title) {
       alert ("prop:  "+prop)
       }
       */


  //    var strExt = ".TXT";
    //  if (csv)
       let strExt = ".CSV";
      var vNames = FilterStore.getOrgAndFarmNamesByFarmId(FilterStore.getSelectedFarmID());
      var now = moment.utc(new Date());
      var fileName = vNames.farm+" "+(strTitle.trim())+ " "+now.format("YYYY-MM-DD")+strExt;
      FileSaver.saveAs(new Blob([str], {type: 'text/plain'}), fileName);


  } catch (er) {
      alert("buttonexp:  " + er);
  }




  }
   




  render() {





    return (
      <div className="wrap">
        <div  className="buttonWrap">
        <Button  size="md" value="2" variant="outline-dark" onClick = {this.handleClick}     
      >   CSV file   </Button>

      

</div>
      </div>
    );
  }
}
